import Player from "./Player";
import store from "./store/index";
import { Provider as ReduxProvider } from "react-redux";
import "./App.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

function App() {
  return (
    <ReduxProvider store={store}>
      <Player />
    </ReduxProvider>
  );
}

export default App;
