import React from "react";
import WaveformData from "waveform-data";
import usePlayer from "../../../usePlayer";

export default function Waveform({ url }) {
  const audioContext = new AudioContext();
  const { changeVisibility } = usePlayer();
  React.useEffect(() => {
    console.log("fetching url", url);
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const options = {
          audio_context: audioContext,
          array_buffer: buffer,
          scale: 128,
        };

        return new Promise((resolve, reject) => {
          WaveformData.createFromAudio(options, (err, waveform) => {
            if (err) {
              reject(err);
            } else {
              const canvas = document.getElementById("canvas");

              drawWaveform(canvas, waveform);
              changeVisibility(true);
              resolve(waveform);
            }
          });
        });
      })
      .then((waveform) => {
        console.log(`Waveform has ${waveform.channels} channels`);
        console.log(`Waveform has length ${waveform.length} points`);
      });
  }, []);

  const scaleY = (amplitude, height) => {
    const range = 256;
    const offset = 128;

    return height - ((amplitude + offset) * height) / range;
  };

  const drawWaveform = (canvas, waveform) => {
    // const waveform = WaveformData.create(raw_data);

    const scaleY = (amplitude, height) => {
      const range = 256;
      const offset = 128;

      return height - ((amplitude + offset) * height) / range;
    };

    const ctx = canvas.getContext("2d");
    ctx.beginPath();

    const channel = waveform.channel(0);

    // Loop forwards, drawing the upper half of the waveform
    for (let x = 0; x < waveform.length; x++) {
      const val = channel.max_sample(x);

      ctx.lineTo(x + 0.5, scaleY(val, canvas.height) + 0.5);
    }

    // Loop backwards, drawing the lower half of the waveform
    for (let x = waveform.length - 1; x >= 0; x--) {
      const val = channel.min_sample(x);

      ctx.lineTo(x + 0.5, scaleY(val, canvas.height) + 0.5);
    }

    //   ctx.strokeStyle = "#FF0000";
    //   ctx.fillStyle = "#FF0000";
    // ctx.strokeStyle = "rgba(255, 0, 0, 0.5)";
    // ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
    ctx.lineWidth = 4;
    ctx.lineCap = "round";
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
  };

  return (
    <div id="waveform-container">
      <canvas
        id="canvas"
        width={document.body.offsetWidth - 150}
        height="20"
      ></canvas>
    </div>
  );
}
