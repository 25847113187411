import React from "react";
import style from "./styles/player.module.css";
import BluePlayer from "./components/themes/BluePlayer/index";
import { useDispatch, useSelector } from "react-redux";
import { setPlayer, setPlayerProgress } from "./store/actions";
import axios from "axios";
import { setMainPlayer, setSoundData } from "./store/actions/player";
export default function Player() {
  const dispatch = useDispatch();
  // const [sound, setSound] = React.useState(false);
  const { visbiility, soundData } = useSelector((state) => state.player);
  React.useEffect(() => {
    const receiveMessage = (e) => {
      if (e.data.type == "mainPlayerAvailable") {
        if (e.data.available) {
          dispatch(setMainPlayer(true));
        }
      }
    };
    window.addEventListener("message", receiveMessage);

    const soundId = window.location.pathname.replace("/songId=", "");
    axios
      .get(
        `https://teamsbackenddev.audioone.cloud/api/v1/email/embed/${soundId}`
      )
      .then((res) => {
        const data = res.data;

        dispatch(setSoundData(data));

        window.parent.postMessage(
          {
            type: "checkMainPlayer",
            data: "checkMainPlayer",
          },
          "*"
        );

        const player = new Audio(data.speechUrl);
        player.load();

        player.ontimeupdate = function () {
          dispatch(
            setPlayerProgress({
              duration: player.duration,
              currentTime: player.currentTime,
            })
          );
        };

        player.onended = function () {
          console.log("track ended");
        };
        player.onloadedmetadata = async function () {
          console.log("on loaded metadata");
        };
        dispatch(setPlayer(player));
      });
  }, []);

  return (
    <div className={style.container}>{soundData ? <BluePlayer /> : ""}</div>
  );
}
