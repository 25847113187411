import {
  SET_QUEUE,
  ADD_QUEUE,
  SET_CURRENT_PLAYING,
  SET_VOLUME,
  SET_PLAYING,
  SET_SPEED,
  SET_PLAYER,
  SET_PLAYER_PROGRESS,
  SET_CLICKED_TIME,
  SET_VISIBILITY,
  SET_MAIN_PLAYER,
  SET_SOUND_DATA,
} from "../constants";

export const setQueue = (cond) => ({
  type: SET_QUEUE,
  payload: cond,
});

export const addQueue = (cond) => ({
  type: ADD_QUEUE,
  payload: cond,
});

export const setCurrentlyPlaying = (cond) => ({
  type: SET_CURRENT_PLAYING,
  payload: cond,
});

export const setVolume = (cond) => ({
  type: SET_VOLUME,
  payload: cond,
});

export const setPlaying = (cond) => ({
  type: SET_PLAYING,
  payload: cond,
});

export const setSpeed = (cond) => ({
  type: SET_SPEED,
  payload: cond,
});

export const setPlayer = (cond) => ({
  type: SET_PLAYER,
  payload: cond,
});

export const setClickedTime = (cond) => ({
  type: SET_CLICKED_TIME,
  payload: cond,
});

export const setVisibility = (cond) => ({
  type: SET_VISIBILITY,
  payload: cond,
});

export const setPlayerProgress = (cond) => ({
  type: SET_PLAYER_PROGRESS,
  payload: cond,
});

export const setMainPlayer = (cond) => ({
  type: SET_MAIN_PLAYER,
  payload: cond,
});

export const setSoundData = (cond) => ({
  type: SET_SOUND_DATA,
  payload: cond,
});
