import React from "react";
import style from "../bluePlayer.module.css";
import usePlayer from "../../../usePlayer";
import { useSelector } from "react-redux";
export default function PlayButton({ onClick }) {
  const { toggleAudio, playing } = usePlayer();
  const { soundData } = useSelector((state) => state.player);

  return (
    <div
      onClick={() => {
        onClick();
        toggleAudio();
        window.parent.postMessage(
          { type: "playFromEmbed", data: soundData },
          "*"
        );
      }}
      className={style.playButton}
    >
      {playing ? <i class="fas fa-pause"></i> : <i class="fas fa-play"></i>}
    </div>
  );
}
