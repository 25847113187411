import {
  SET_QUEUE,
  ADD_QUEUE,
  SET_CURRENT_PLAYING,
  SET_VOLUME,
  SET_PLAYING,
  SET_SPEED,
  SET_PLAYER,
  SET_PLAYER_PROGRESS,
  SET_CLICKED_TIME,
  SET_VISIBILITY,
  SET_MAIN_PLAYER,
  SET_SOUND_DATA,
} from "../constants";

var defaultState = {
  audioPlayer: null,
  queue: [],
  activeTrack: null,
  volume: 1,
  playing: false,
  speed: 0,
  playerProgress: {},
  clickedTime: null,
  visibility: false,
  mainPlayer: false,
  soundData: null,
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SOUND_DATA: {
      let newState = Object.assign({}, state);
      newState.soundData = action.payload;

      return newState;
    }

    case SET_MAIN_PLAYER: {
      let newState = Object.assign({}, state);
      newState.mainPlayer = action.payload;

      return newState;
    }

    case SET_VISIBILITY: {
      let newState = Object.assign({}, state);
      newState.visibility = action.payload;

      return newState;
    }

    case SET_PLAYER_PROGRESS: {
      let newState = Object.assign({}, state);
      newState.playerProgress = action.payload;

      return newState;
    }

    case SET_CLICKED_TIME: {
      let newState = Object.assign({}, state);
      newState.clickedTime = action.payload;

      return newState;
    }

    case SET_PLAYER: {
      let newState = Object.assign({}, state);
      newState.audioPlayer = action.payload;

      return newState;
    }
    case SET_QUEUE: {
      let newState = Object.assign({}, state);
      newState.queue = action.payload;

      return newState;
    }

    case ADD_QUEUE: {
      let newState = Object.assign({}, state);
      newState.queue = [...newState.queue, action.payload];
      return newState;
    }
    case SET_CURRENT_PLAYING: {
      let newState = Object.assign({}, state);
      newState.activeTrack = action.payload;
      return newState;
    }
    case SET_VOLUME: {
      let newState = Object.assign({}, state);
      newState.volume = action.payload;
      return newState;
    }
    case SET_PLAYING: {
      let newState = Object.assign({}, state);
      newState.playing = action.payload;
      return newState;
    }
    case SET_SPEED: {
      let newState = Object.assign({}, state);
      newState.speed = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
export default authReducer;
