export const SET_QUEUE = "SET_QUEUE";
export const ADD_QUEUE = "ADD_QUEUE";
export const SET_CURRENT_PLAYING = "SET_CURRENT_PLAYING";
export const SET_VOLUME = "SET_VOLUME";
export const SET_PLAYING = "SET_PLAYING";
export const SET_SPEED = "SET_SPEED";
export const SET_PLAYER = "SET_PLAYER";
export const SET_PLAYER_PROGRESS = "SET_PLAYER_PROGRESS";

export const SET_CLICKED_TIME = "SET_CLICKED_TIME";
export const SET_VISIBILITY = "SET_VISIBILITY";

export const SET_MAIN_PLAYER = "SET_MAIN_PLAYER";
export const SET_SOUND_DATA = "SET_SOUND_DATA";
