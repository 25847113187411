import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPlaying,
  setClickedTime,
  setVolume,
  setVisibility,
} from "../store/actions";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

function UseAudioPlayer() {
  const {
    playing,
    audioPlayer,
    volume,
    playerProgress,
    clickedTime,
    activeTrack,
    queue,
    speed,
    mainPlayer,
    soundData,
  } = useSelector((state) => state.player);

  const [playerDuration, setPlayerDuration] = useState(0);
  const dispatch = useDispatch();

  const toggleAudio = () => {
    dispatch(setPlaying(!playing));
  };

  const changeVolume = (val) => {
    dispatch(setVolume(val));
  };

  const changeVisibility = (val) => {
    dispatch(setVisibility(val));
  };

  const formatDuration = () => {
    return moment
      .duration(playerProgress.duration - playerProgress.currentTime, "seconds")
      .format("mm:ss", { trim: false });
  };

  const onChangePosition = (value) => {
    if (!mainPlayer) {
      var position = playerProgress.duration * value;
      audioPlayer.currentTime = position;
    }
  };

  useEffect(() => {
    const receiveMessage = (e) => {
      if (e.data.type == "playQueue") {
        setPlayerDuration(e.data.remtime);
        dispatch(setPlaying(e.data.playing));
      }
    };

    window.addEventListener("message", receiveMessage);
  }, []);

  useEffect(() => {
    if (audioPlayer) audioPlayer.volume = volume;
  }, [volume]);

  useEffect(() => {
    if (!mainPlayer)
      if (audioPlayer) playing ? audioPlayer.play() : audioPlayer.pause();
  }, [playing]);

  useEffect(() => {
    if (audioPlayer) {
      if (clickedTime !== null) {
        audioPlayer.currentTime = clickedTime;
        dispatch(setClickedTime(null));
      }
    }
  }, [playerProgress]);

  const duration = !mainPlayer
    ? playerProgress.duration
      ? formatDuration()
      : "0:00"
    : playerDuration;

  const sliderPosition =
    playerProgress.duration && playerProgress.currentTime
      ? playerProgress.currentTime / playerProgress.duration
      : 0;

  return {
    toggleAudio,
    playing,
    volume,
    playerProgress,
    clickedTime,
    activeTrack,
    queue,
    changeVolume,
    duration,
    sliderPosition,
    onChangePosition,
    changeVisibility,
  };
}

export default UseAudioPlayer;
