import React from "react";
import PlayButton from "./components/PlayButton";
import RewindButton from "./components/RewindButton";
import VolumeButton from "./components/VolumeButton";
import ProgressBar from "./components/ProgressBar";
import DurationTimer from "./components/DurationTimer";

import style from "./bluePlayer.module.css";
import usePlayer from "../../usePlayer";
import Waveform from "./components/Waveform";
import { useSelector } from "react-redux";
export default function Index() {
  const sound = useSelector((state) => state.player.soundData);
  const { playing } = usePlayer();
  const [initialised, setInitialised] = React.useState(false);
  return (
    <div className="d-flex">
      <PlayButton onClick={() => setInitialised(true)} />
      <div className="mr-4" style={{ flex: 1 }}>
        <h3 className={style.title}>{sound.title}</h3>

        <div className="d-flex">
          {initialised ? (
            <>
              <RewindButton />
              <VolumeButton />
              <ProgressBar />
              <DurationTimer />
            </>
          ) : (
            <Waveform url={sound.speechUrl} />
          )}
        </div>
      </div>
    </div>
  );
}
