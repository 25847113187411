import React from "react";
import style from "../bluePlayer.module.css";
import { Slider } from "antd";
import usePlayer from "../../../usePlayer";
export default function VolumeButton() {
  const { volume, changeVolume } = usePlayer();
  return (
    <div className={style.volumeButton}>
      {volume == 0 ? (
        <i className="fas fa-volume-mute" onClick={() => changeVolume(1)}></i>
      ) : (
        <i onClick={() => changeVolume(0)} className="fas fa-volume-up"></i>
      )}
      <div className={style.volumeSlider}>
        <Slider
          tooltipVisible={false}
          defaultValue={0.5}
          min={0}
          max={1}
          step={0.1}
          onChange={(val) => changeVolume(val)}
        />
      </div>
    </div>
  );
}
