import { createStore, applyMiddleware, combineReducers } from "redux";

// // Logger with default options
import logger from "redux-logger";
import thunk from "redux-thunk";

import reducer from "./reducers/index";

const rootReducer = combineReducers(reducer);

const configureStore = (initialState) => {
  var store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk, logger)
  );

  return store;
};

const store = configureStore();
export default store;
