import React from "react";
import { Slider } from "antd";
import style from "../bluePlayer.module.css";
import usePlayer from "../../../usePlayer";
export default function ProgressBar() {
  const { sliderPosition, onChangePosition } = usePlayer();
  return (
    <div className={`blueProgressBar  ${style.ProgressBar}`}>
      <Slider
        tooltipVisible={false}
        value={sliderPosition}
        min={0}
        max={1}
        step={0.00001}
        onChange={(val) => onChangePosition(val)}
      />
    </div>
  );
}
